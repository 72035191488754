



/*

Responsive design helpers

 */

function responsive_init() {
    responsive_update();
}

function responsive_update(force, no_animation) {
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force)
    {
        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: "+breakpoints[i]+"px)").matches) {
                    if ($(places[breakpoints[i]]).length)  {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}


/*

Scroll to needed objects

 */

function goto_init()
{
    $(document).on("click", ".js-goto", function (e) {
        var href = $(this).attr("href");
        var hash = href.split('#');
        if (hash.length > 1) {
            hash = '#'+hash.pop();
            var $o = $(hash);
            if ($o.length) {
                e.preventDefault();
                var $this = $(this);
                if ($this.closest(".js-goto-list").length)
                {
                    $this.closest(".js-goto-list").find("li").removeClass("active");
                    $this.closest("li").addClass("active");
                    tabs_update_pointer($this.closest(".js-tabs"));
                }
                if ($this.closest(".js-goto-hash-change").length)
                {
                    location_hash_update($this.attr("href"));
                }
                $this.trigger('goto');
                goto_object($o);
            }
        }
    });
}

function goto_object($o, speed, doffset)
{
    if (typeof speed === 'undefined') {
        speed = 500;
    }
    if ($o.length)
    {
        var offset = $o.offset().top;
        if (typeof doffset !== 'undefined') {
            offset += doffset;
        }
        var offset_el = $(".js-goto-offset").get(0);
        if (offset_el) {
            offset -= offset_el.offsetHeight;
        }
        if ($o.data("goto-offset-element")) {
            $($o.data("goto-offset-element")).each(function(){
                offset -= this.offsetHeight;
            });
        }
        goto_offset(offset, speed);
    }
}

function goto_offset(offset, speed)
{
    if (typeof speed === 'undefined') {
        speed = 800;
    }
    $("html, body").stop(true, true).animate({scrollTop: offset}, speed, function(){
        $(this).trigger('goto-end');
    });
}

function activate_goto_link($obj) {
    $obj.addClass("active").siblings().removeClass("active")
        .end().parent("li").addClass("active").siblings().removeClass("active");
    $obj.trigger("link-activated");
}


/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited-globally")) {

        $(window).on("resize", function(){
            delay_img_to_bg(function(){
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited-globally");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        if ($img.length) {
            var src = $img[0].currentSrc;
            if (!src) {
                src = $img.attr('src');
            }
            if (src) {
                $imgtobg.css("background-image", "url('" + src + "')");
                $imgtobg.addClass("img-to-bg--inited");
            }
        }
    });
}

var delay_img_to_bg = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();



function site_nav_init() {

    $('.js-section-controls').addClass('active');

    addCSSRule('section-overlay-scroll-width', '' +
        '.section__scroll.has-scrollbar { margin-right: '+getScrollbarWidth()+'px;}' +
        '.js-section-action-allowed .section--overlay.active .section__scroll.has-scrollbar { margin-right: 0;}' +
        '');

    section_scrollbar_update();
    $(window).on("resize load", function () {
        section_scrollbar_update();
    });

    $(document).on("click", ".js-section-goto", function (e) {
        var selector = $(this).attr("href");
        section_goto_by_selector(selector);
    });

    $(document).on("click", ".js-section-prev", function (e) {
        section_goto_by_dir(-1);
    });

    $(document).on("click", ".js-section-next", function (e) {
        section_goto_by_dir(1);
    });

    $(".touchevents .wrap").swipe({
        swipe: $.throttle(500, true, function(event, direction, distance, duration, fingerCount, fingerData) {
            if (direction=="right" || direction=="left")
            {
                var delta = (direction=="right")?-1:((direction=="left")?1:0);
            }
            else
            {
                var delta = (direction=="down")?-1:((direction=="up")?1:0);
                if (!slide_allowed(delta)) {
                    return true;
                }
            }
            body_swipe(delta);
        }),
        threshold: Math.min(75, window.innerWidth/20),
        cancelThreshold: Math.min(75, window.innerWidth/20),
        maxTimeThreshold: 500,
        excludedElements: "",
        allowPageScroll: "vertical"
    });

    $('.wrap').on('mousewheel', $.throttle(500, true, function(e) {
        if (e.ctrlKey || e.altKey || e.shiftKey || !slide_allowed(-1*e.deltaY)) {
            return true;
        }
        body_swipe(-1*e.deltaY);
    }));

    $(document).on('keyup', function(e){
        var key = e.which || e.keyCode;
        console.log(key);
        if (key === 38 || key === 33){
            body_swipe(-1);
        }
        if (key === 40 || key === 34){
            body_swipe(1);
        }
    });

    $("body").addClass("js-section-action-allowed");

    if (!location.hash) {
        section_on_goto($('.js-section.active-default'), true);
    }
    else {
        section_goto_by_hash(true);
    }
    $('body')[0].offsetHeight;
    $('.js-section').addClass('loaded');

    $(window).on("popstate", function() {
        section_goto_by_hash();
    });

    $('.js-section-timer').on('webkitTransitionEnd transitionend', function (e) {
        var $target = $(e.target).parent();
        if ($target.hasClass('js-section') && $target.hasClass('active')) {
            $("body").addClass("js-section-action-allowed");
        }
    });

}

function section_scrollbar_update() {
    $('.js-section .section__scroll').each(function(){
        $(this).toggleClass('has-scrollbar', $(this).hasScrollBar());
    });
}

function section_goto_by_hash(notransition) {
    var hash = location.hash;
    section_goto_by_selector(hash, notransition);
    $('body').addClass('js-section-action-allowed');
}

function body_swipe(direction) {
    //if ($('.js-section-action-allowed').length) {
        section_goto_by_dir(direction);
    //}
}

function menu_opened() {
    return $('.menu-overlay-active').length;
}

function fancybox_opened() {
    return $('.fancybox-active').length;
}

function slide_allowed(dir) {
    if (menu_opened() || fancybox_opened()) {
        return false;
    }
    var $scroll = $('.js-section.active .section__scroll');
    if ($scroll.length) {
        //console.log('content scroll', dir, $scroll.startScrollBar(), $scroll.endScrollBar());
        if ($scroll.hasScrollBar()) {
            if (dir > 0) {
                return $scroll.endScrollBar();
            }
            else if (dir < 0) {
                return $scroll.startScrollBar();
            }
        }
        else {
            return true;
        }
    }
}

function section_goto_by_dir(dir, notransition) {
    var $active = $('.js-section.active');
    if ($active.length) {
        if (dir > 0) {
            $goto = $active.next('.js-section');
        }
        else {
            $goto = $active.prev('.js-section');
        }
    }
    section_on_goto($goto, notransition);
}

function section_goto_by_selector(selector, notransition) {
    var $goto = $(selector);
    section_on_goto($goto, notransition);
}

function section_on_goto($goto, notransition) {
    if (!$goto.length) {
        return;
    }
    if (typeof notransition !== 'undefined' && notransition) {
        $("body").addClass('no-transition no-animation');
    }
    $('body').removeClass('js-section-action-allowed');
    $('body').attr('data-section', $goto.attr('id'));

    $(".js-section-prev").toggleClass('disabled', !$goto.prev('.js-section').length);
    $(".js-section-next").toggleClass('disabled', !$goto.next('.js-section').length);

    $goto.siblings('.js-section').addBack().removeClass('before-active prev-active');
    $goto.siblings('.js-section.active').addClass('prev-active');
    $goto.prevAll('.js-section').addClass('before-active');
    $goto.addClass('active').siblings('.js-section').removeClass('active');

    if ($goto.attr('id')) {
        location_hash_update('#'+$goto.attr('id'));
    }
    if (typeof notransition !== 'undefined' && notransition) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}

function disable_body_scroll() {
    $('.section__scroll').not('.scroll-body-disabled').each(function(){
        bodyScrollLock.disableBodyScroll($(this)[0]);
        $(this).addClass('scroll-body-disabled');
    });
}