/*
* Form handlers
* */


function form_process_init() {

    $(document).on("submit.valid click perform-action", ".js-action", function(e){
        var $this = $(this);
        if ($this.is("form") && e.type != "submit") {
            return true;
        }
        e.preventDefault();
        var url = $this.attr("href");
        var method = "get";
        var data = null;

        // Добавим уникальный хеш для текущей отправки файлов (можно по нему определить общий пакет файлов)
        $this.find('.js-file-upload-hash').val(new Date().getTime());

        if ($this.is("form")) {
            url = $this.attr("action");
            method = $this.attr("method");
        }
        if ($this.attr("data-action-url")) {
            url = $this.attr("data-action-url");
        }
        if ($this.attr("data-action-method")) {
            method = $this.attr("data-action-method");
        }

        if ($this.is("form")) {
            data = $this.serialize();

            if (method.toLowerCase() != 'get' && window.FormData !== undefined) {
                data = new FormData($this[0]);
            }
            $this.data('serialized', data);
            $this.data('serializedArray', $this.serializeArray());
        }

        if ($this.find('.js-file-upload-start').length) {
            // Запускаем загрузку всех файлов (по отдельности)
            form_process_before_send($this);
            $this.find('.js-file-upload-start').trigger('click');
        }
        else {
            $.ajax({
                url: url,
                type: method,
                data: data,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function () {
                    form_process_before_send($this);
                },
                complete: function (response) {
                    form_process_complete($this, response);
                },
                success: function (response) {
                    form_process_success($this, response);
                },
                error: function (jqXHR) {
                    form_process_errors($this, jqXHR);
                }
            });
        }
    });

    $(document).on("after-action-success", ".js-action-reset-after", function(e){
        $(this)[0].reset();
    });
}

function form_process_before_send($this) {
    $this.find(":input").prop("disabled", true).closest(".textfield-wrapper").addClass("disabled");
    $this.find(".js-form-disable-on-submit").addClass("disabled");
    var $btn = $this.find(".loader-on-submit");
    if (window['loader_add']) {
        loader_add($btn);
    }
}

function form_process_complete($this, response) {
    $this.find(":input").not("[data-disabled]").prop("disabled", false).closest(".textfield-wrapper").removeClass("disabled");
    $this.find(".js-form-disable-on-submit").not("[data-disabled]").removeClass("disabled");
    $this.find(":password").val("");
    var $btn = $this.find(".loader-on-submit");
    if (window['loader_remove']) {
        loader_remove($btn);
    }
    $this.trigger("after-action-complete", response);
}

function form_process_success($this, response) {
    if (response.popup) {
        if (response.text) {
            $.fancybox.close();
            $.fancybox.open(response.text, $.fancybox.options_modal);
        }
    }
    else {
        var $message = $this.find(".js-message");
        if (!$message.length) {
            $message = $this.find($this.data("message"));
        }
        if (!$message.length) {
            $message = $($this.data("message"));
        }
        $this.find(".js-message-container").html(response.text);
        bind_widgets($this);
        if ($message.length) {
            $message.addClass("active");
            $this.addClass("inactive");
            if ($message.offset().top < $(window).scrollTop() + $(".header").outerHeight()) {
                goto_object($message, null, $(".header").outerHeight()*-1);
            }
            if ($this.data("message-autoclose"))
            {
                setTimeout(function(){
                    $message.removeClass("active");
                    $this.removeClass("inactive");
                }, $this.data("message-autoclose"));
            }
        }
    }
    $this.trigger("after-action-success", response);
}

function form_process_errors($this, jqXHR) {
    console.log(jqXHR.status, jqXHR.responseJSON);
    var error_text;
    if (jqXHR.status === 401) {
        error_text = 'Access denied.';
    }
    else if (jqXHR.status === 422) {
        form_process_errors_show($this, jqXHR.responseJSON);
    }
    else {
        error_text = 'Произошла ошибка. Попробуйте ещё раз.';
    }
    if (error_text) {
        alert(error_text);
    }
    $this.trigger("after-action-error", jqXHR);
}

function form_process_errors_show($this, response) {
    var index = 0;
    $.each(response, function(field_name, field_errors){
        $field = $this.find("[name='" + field_name + "']");
        if (!index) {
            $field.trigger("focus");
        }
        $field.addClass("error");
        var $append_to = $field.closest('.form__item-field');
        if (!$append_to.length) {
            $append_to = $field.parent();
        }
        $append_to.find('.form__error').remove();
        $.each(field_errors, function(error_index, error_text) {
            $append_to.append("<div class='form__error form__error--"+error_index+"'>" + error_text + "</div>");
        });
        index++;
    });
}




/*

 Form Validation

 */

function validate_init()
{
    if (!$.validator) return;

    if (!$("body").hasClass("validate-inited")) {

        $(document).on("reset", ".form-validate", function () {
            $(this).find(".form__item, :input").removeClass("error");
            setTimeout(function () {
                $(".js-filer").each(function(){
                    $(this).prop("jFiler").reset();
                });
                $(this).find(":input").trigger("change");
                $(this).find(".checkbox-plain-js input[type='checkbox'], .checkbox-plain-js input[type='radio']").trigger("change-pseudo");
            }, 50);
        })

        $.validator.setDefaults({
        });

        $.extend($.validator.messages, {
            required: "Обязательное поле.",
            email: "Некорректный E-mail.",
            phonecomplete: "Неполный номер телефона.",
            maskcomplete: "Заполните полностью.",
        });

        $.validator.addMethod("email", function( value, element ) {
            return this.optional( element ) || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value ); // /^[a-zA-Z\-\._]+@[a-z0-9\-]+(\.[a-z0-9\-]+)*\.[a-z]+$/i
        });

        $.validator.addMethod("phonecomplete", function(value, element) {
            return this.optional(element) || (value.replace(/\D/, "").length >= 7 && $(element).inputmask('isComplete'));
        });

        $.validator.addMethod("maskcomplete", function(value, element) {
            return this.optional(element) || (value.replace(/\D/, "").length >= 7 && $(element).inputmask('isComplete'));
        });

        $.validator.addMethod("password", function(value, element) {
            return this.optional(element) || value.length >= 6;
        });

        $.validator.addMethod("passwordconfirm", function(value, element) {
            var $password = $($(element).data("rule-passwordconfirm-password"));
            return this.optional(element) || !$password.length || !$password.val() || value === $password.val();
        });

        $.each($.validator.methods, function (key, value) {
            $.validator.methods[key] = function () {
                if(arguments.length > 0) {
                    arguments[0] = $.trim(arguments[0]);
                }
                return value.apply(this, arguments);
            };
        });

        $(document).on("keyup blur change check-form", ".form-disabled-until-valid :input", function () {
            var $form = $(this).closest(".form-disabled-until-valid");
            var $button = $form.find(".disabled-until-valid");
            $button.prop('disabled', !$form.validate().checkForm());
        });

        $(document).on("change change-checkbox-valid", "input[type='checkbox'], input[type='radio']", function () {
            var $form = $(this).closest("form");
            var validator = $form.data("validator");
            if (validator) $(this).valid();
        });

        $("body").addClass("validate-inited");
    }

    var $forms = $(".form-validate").not(".form-validate-inited");
    $forms.each(function(){
        $(this).validate({
            onkeyup: false,
            normalizer: function( value ) {
                return $.trim( value );
            },
            errorClass: 'form__error',
            validClass: 'valid',
            errorElement: 'div',
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('error').removeClass('valid');
                $(element).closest(".form__item-field").addClass('error').removeClass('valid');
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).addClass('valid').removeClass('error');
                $(element).closest(".form__item-field").addClass('valid').removeClass('error');
            },
            errorPlacement: function(error, element) {
                $(element).closest(".form__item-field").append(error);
            },
            submitHandler: function(form, e) {
                $(form).trigger("submit.valid");
            }
        });

        $(this).filter(".form-disabled-until-valid").find(":input").first().trigger("check-form");

        $(this).addClass("form-validate-inited");
    });

}
