




/*
* SVG icon url update
* */

function icons_load() {
    var url = $('body').data('svg-sprite-url');
    if (url) {
        $.ajax({
            url: url,
            localCache: true,
            cacheTTL: 1,
            dataType: 'text',
            cacheKey: 'svg-sprite'
        }).done(function (response) {
            $('body').append('<div id="svg-icons-container" style="height: 0; width: 0; position: absolute; top: -99999px; left: 0; visibility: hidden;">'+response+'</div>');
        });
    }
}

function icons_init($o) {
    if (typeof $o === 'undefined') {
        $o = $("svg.icon");
    }
    $o.not('.icon-url-changed').each(function(){
        var $use = $(this).find('use');
        $use.each(function(){
            var href = $(this).attr('xlink:href');
            if (href && href.substr(0, 1) == '#') {
                $(this).attr('xlink:href', $('body').data('svg-sprite-url') + href);
            }
        })
        $(this).addClass('icon-url-changed');
    });
}




